

export const closeArray = (a: any[]) => {
    return a.concat(a.slice(0, 1))
}
  
export const min_max_norm = (row: number[]): number[] => {
    var min = Math.min(...row)
    var max = Math.max(...row)
    var numerator = row.map(cur => cur-min)
    var denominator = max - min
    return numerator.map(cur => cur/denominator)
}

export const defaultPlotlyArguments = {
    layout: {
      autosize: true,
      showlegend: false,
      margin: {
        t: 30,
        b: 40,
        l: 40,
        r: 20,
      },
      angularaxis: {
        showticklables: false,
        fixedrange: true
      },
      radialaxis: {
        showticklables: false,
        fixedrange: true
      },
    },
    config: {
      displaylogo: false,
      displayModeBar: false,
    //   staticPlot: true
    },
    style: {
      width: "100%",
      height: "100%"
    }
  }

