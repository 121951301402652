export const aryballeColors = {
    primary: {
        rgb: [38, 38, 60],
        hex: '#26263c'
    },
    magenta: {
        rgb: [253, 66, 130],
        hex: '#fd4282'
    }, 
    purple: {
        rgb: [116, 81, 241],
        hex: '#7451f1'
    },
    turquoise: {
        rgb: [77, 229, 219],
        hex: '#4de5db'
    }, 
    blue: {
        rgb: [17, 147, 245],
        hex: '#1193f5'
    }, 
    yellow: {
        rgb: [255, 197, 0],
        hex: '#ffc500'
    }, 
  }
  
export const basicColors = ["red", "magenta", "volcano", "green", "blue", "purple", "geekblue", "orange", "yellow"]
  
export const chemicalFamiliesColorMap: Record<string, string> = {
    Amine: '#fff566',
    Ether: '#87e8de',
    Hydrocarbon: '#d9d9d9',
    Ketone: '#d3adf7',
    Acid: '##ffe58f',
    Aromatic: '#b7eb8f',
    Sulfurous: '#ffd666',
    Ester: '#95de64',
    CyclicEther: '#e6fffb',
    Nitrile: '#ffd8bf',
    Aldehyde: '#bae7ff',
    Pyrazine: '#adc6ff',
    Alcohol: '#597ef7'
  }
  
export const odorStrengthColorMap: Record<string, string>= {
    high: '#ff9c6e',
    none: '#f6ffed',
    medium: '#f4ffb8',
    'very high': '#cf1322'
  }
  
export const odorTypeColorMap: Record<string, string> = {
    'citrus': '#fff566',
    'cheesy': '#fff566',
    'acidic': '#fff566',
    'floral': '#ffd6e7',
    'fruity': '#ffd6e7',
    'alcoholic': '#40a9ff',
    'chemical': '#ff4d4f',
    'orris': '#d3adf7',
    'spicy': '#ffc069',
    'melon': '#ffc069',
    'alliaceous': '#d3f261',
    'aldehydic': '#e6f7ff',
    'powdery': '#e8c3c1',
    'odorless': '#f0f0f0',
    'phenolic': '#f0f0f0',
    'green': '#73d13d',
    'herbal': '#73d13d',
    'sulfurous': '#fadb14',
    'terpenic': '#ffc53d',
    'waxy': '#ffc53d',
    'honey': '#ffc53d',
    'licorice': '#434343',
    'ethereal': '#87e8de',
    'solvent': '#ff4d4f',
    'mentholic': '#87e8de',
    'minty': '#d9f7be',
    'woody': '#ad4e00',
    'nutty': '#ad4e00',
    'tonka': '#ad4e00',
    'caramellic': '#ad4e00',
    'popcorn': '#ad4e00',
    'marine': '#40a9ff',
    'fermented': '#ffefd1',
    'creamy': '#ffefd1',
    'coconut': '#ffefd1',
    'fatty': '#ffefd1',
    'rose': '#ffd6e7'
}