/*
N - number of records
M - number of spots
*/

export type signal = number[][]; // NxM matrix
export type signature = number[]; // 1xM matrix

export interface record {
    id: string; // Used to identify within excludedRecords, learningRecords and testRecords arrays
    name: string; // Used to search and show in record lists
    timestamp: number;
    group: string; // Used to classify records
    tags: string[]; // Used to search for the records
    signal: signal; // Raw signal (sensograms)
    spotfile: {
        spots: string[]; // List of spot names, e.g 22[B1]
    };
    device: {};
    metadata: any; // Some general key-value object with some camera/VCSEL parameters, sensogram FPS, batch No etc.
    baselineLeft: number; // baseline left
    baselineRight: number; // baseline right
    plateauLeft: number; // plateau left
    plateauRight: number; // baseline right
    acquisitionMode: string; // E.g. Teaching, Monitoring, Questioning etc
}

export enum NormType {
    none = 0,
    minMaxNorm = 1,
    l2Norm = 2,
    zNorm = 3
}

/*
Full ellipse definition. To be used in the App
*/
export interface ellipse {
    group: string;
    cx: number; // Center, x
    cy: number; // Center, y
    a: number; // Semi-major axis
    b: number; // Semi-minor axis
    theta: number; // Counter-clockwise angle of rotation in radians (between the x axis and the semi-major axis)
}

/*
Simplified version of ellipse. Already calculated, translated and rotated focal points (x,y)
To be transmitted to the watch
*/
export interface simplifiedEllipse {
    group: string;
    p: number; // Perimeter: 2*a
    focusAx: number; // Coordinates of the right focus of an ellipse, x
    focusAy: number; // Coordinates of the right focus of an ellipse, y
    focusBx: number; // Coordinates of the left focus of an ellipse, x
    focusBy: number; // Coordinates of the left focus of an ellipse, y
}

/*
Search object represents user entered search rules.
Should facilitate record re-search
*/
export interface searchOptions {
    tags: string[]; // list of tags that were used to search the records
    groups: string[]; // list of groups that were used to search for records
    dateFrom: number; // timestamp
    dateTo: number; // timestamp
    excludedRecords: string[]; // Manually excluded records
    effectiveRecords: string[]; // List of final list of records' IDs after search and exclusion: effectiveRecords = found-excluded
}

export interface signatureOptions {
    normType: NormType;
    plateauFraction: number;
}

export interface preprocessingOptions {
    excludedSpots: number[]; // Indices of spots to exclude
    excludedRecords: string[]; // ? Possible duplicate of excludedSpots in searchOptions
}

export interface analysisOptions {
    sigmaMultiplier: number;
}

/*
Result object represents analysis results of a referenceObject.
i.e. the output of the application of user-provided rules on a set of records
*/
export interface resultObject {
    cqs: number;
    ellipses: number[];
    effectiveSigmaMultiplier: number;
    driftCorrectionEigenvectors: number[][]; // Mx2 matrix, e.g 16x2 if number of spots = 16
    pcaEigenvectors: number[][]; // Mx2 matrix, e.g 16x2 if number of spots = 16
}

/*
Reference Object in the App
*/
export interface referenceObject {
    id: string;
    timestamp: number;
    name?: string;
    learningRecords: string[];
    testRecords: string[];

    searchTags: string[];
    searchGroups: string[];
    searchNames: string[];
    searchSpotfile: string[];
    searchDataFrom: number;
    searchDateTo: number;

    normType: NormType;
    plateauFraction: number;
    excludedSpots: number[]; // Indices of spots to exclude
    sigmaMultiplier: number;
    driftCorrectionTracer: string;

    resultCqs: number;
    resultEffectiveSpotfile: string[];
    resultEffectiveSigmaMultiplier: number;
    resultDriftCorrectionEigenvectors: number[][]; // Mx2 matrix, e.g 16x2 if number of spots = 16
    resultPcaEigenvectors: number[][]; // Mx2 matrix, e.g 16x2 if number of spots = 16
    resultPcaExplainedVarianceRatio: number[]; // 2 values which correcspond to EVR for each of components

    resultGroupedSignatures: Record<string, signature[]>;
    resultGroupedEllipses: Record<string, number[]>;
    resultGroupedProjections: Record<string, number[][]>;

    // searchOptions: searchOptions,  // Describes how to search for data
    // preprocessingOptions: preprocessingOptions,  // Describes how to preprocess the data
    // signatureOptions: signatureOptions,  // Describes how to extract signatures from signals
    // analysisOptions: analysisOptions  // Describes how to analyse the data
    // resultObject: resultObject  // Contains output from computation. Should be updated each time the user changes any of the options above.
}

/*
Reference Database to be transmitted to the watch
*/
export interface watchReferenceDatabase {
    id: string; // or name
    normType: NormType;
    excludedSpots: number[];
    driftCorrectionEigenvectors: number[][]; // (optional) Mx2 matrix, e.g 16x2 if number of spots = 16. Watch will correct real-time records with this correction matrix
    pcaEigenvectors: number[][]; // Mx2 matrix, e.g 16x2 if number of spots = 16. Watch will project real-time records onto the PCA 2D space
    // simplifiedEllipses: simplifiedEllipse[]; // Watch will determine whether some real-time record is inside one of the ellipse or not.
    ellipses: ellipse[]
}

export interface analysisResult {
    pcaEigenvectors: number[][];
    pcaExplainedVarianceRatio: number[];
    cqs: number;
    sigma: number;
    projections: number[][];
    groupedProjections: Record<string, number[][]>;
    groupedEllipses: Record<string, number[]>;
    groupedScaledEllipses: Record<string, number[]>;
}

export interface computeResult {
    resultCqs: number;
    resultEffectiveSigmaMultiplier: number;

    resultPcaEigenvectors: number[][];
    resultPcaExplainedVarianceRatio: number[];
    resultDriftCorrectionEigenvectors: number[][];

    resultEffectiveSpotfile: string[];

    resultGroupedSignatures: Record<string, number[][]>;
    resultGroupedProjections: Record<string, number[][]>;
    resultGroupedEllipses: Record<string, number[]>;
}
