import { time } from "console"
import { FC, useEffect, useState } from "react"
import Plot from 'react-plotly.js'
import { chemicalFamiliesColorMap, odorTypeColorMap } from "./colormap"
import { defaultPlotlyArguments } from "./utils"
import { transpose } from 'tsryballe/lib/common' 
import { Compound, Projections } from "../types"
import { getGroupedMap } from 'tsryballe/lib/common'

export const StandardPlotChemistry: FC = () => {

    const [ projections, setProjections ] = useState<Projections | null>(null)
    const [ compounds, setCompounds ] = useState<Compound[]>([])
    
    useEffect(() => {
        fetch('/all_mapped_compounds.json')
        .then(resp => resp.json())
        .then((data: Projections) => setProjections(data))
    }, [])

    useEffect(() => {
        fetch('full_compounds_for_mapping_attempt.json')
        .then(resp => resp.json())
        .then((data: Compound[]) => setCompounds(data))
    }, [])

    if (projections === null || compounds.length === 0) {
        return null
    }

    const components = transpose(projections.components)

    const getColorsByChemicalFamily = () => {
        return projections.targets.map(t => {
            let color = chemicalFamiliesColorMap[t]
            if (color === undefined) {
                return 'blue'
            } else {
                return color
            }
        })
    }

    const getColorsByOdorType = () => {
        return compounds.map(c => {
            let color = odorTypeColorMap[c.OdorType.Name]
            if (color === undefined) {
                return 'rgba(200, 200, 200, 0.2)'
            } else {
                return color
            }
        })
    }

    var groupedProjections: Record<string, number[][]> = getGroupedMap(projections.projections, projections.targets)
    var groupedCompounds: Record<string, Compound[]> = getGroupedMap(compounds, projections.targets)

    var data: Plotly.Data[] = Object.entries(groupedProjections).map(([target, projs]) => {

        let comps = groupedCompounds[target]
        return {
            type: 'scatter3d',
            name: target,
            x: projs.map(row => row[0]),
            y: projs.map(row => row[1]),
            z: projs.map(row => row[2]),
            mode: 'markers',
            text: comps.map((c, i) => `${c.OdorType.Name}: ${c.CommonName}`),
            hoverinfo: 'text',
            marker: {
                size: 5,
                color: chemicalFamiliesColorMap[target],
                // color: comps.map(c => {
                //     let color = odorTypeColorMap[c.OdorType.Name]
                //     if (color === undefined) {
                //         return 'rgba(200, 200, 200, 0.2)'
                //     } else {
                //         return color
                //     }
                // })
            }
        }

    })

    // var data: Plotly.Data[] = [{
    //     type: 'scatter3d',
    //     x: projections.projections.map(row => row[0]),
    //     y: projections.projections.map(row => row[1]),
    //     z: projections.projections.map(row => row[2]),
    //     mode: 'markers',
    //     text: compounds.map((c, i) => `${c.OdorType.Name}: ${c.CommonName} (${projections.targets[i]})`),
    //     hoverlabel: {
    //         // bgcolor: getColors()
    //         // bgcolor: 'grey'
    //     },
    //     hoverinfo: 'text',
    //     marker: {
    //         size: 7,
    //         color: getColorsByOdorType(),
    //     }
    // }]
    components.forEach(c => {
        data.push({
            type: 'scatter3d',
            x: [0, c[0]],
            y: [0, c[1]],
            z: [0, c[2]],
            marker: {
                size: 0,
            },
            line: {
                color: "black",
                width: 3
            },
            hoverinfo: 'none',
        })
    })

    return <Plot
        data={data}
        useResizeHandler={true}
        layout={{...defaultPlotlyArguments.layout, ...{
            title: 'Colored by primary chemical family (functional group)',
            showlegend: true,
            datarevision: Date.now(),
            scene: {
                xaxis: {
                    showline: true,
                    backgroundcolor: 'white',
                    showticklabels: false,
                    title: ''
                },
                yaxis: {
                    showline: true,
                    backgroundcolor: 'white',
                    showticklabels: false,
                    title: ''
                },
                zaxis: {
                    showline: true,
                    backgroundcolor: 'white',
                    showticklabels: false,
                    title: ''
                },
                annotations: components.map((c, i) => ({
                    x: c[0],
                    y: c[1],
                    z: c[2],
                    showarrow: false,
                    text: projections.components_names[i],
                    bgcolor: chemicalFamiliesColorMap[projections.components_names[i]],
                    bordercolor: 'black',
                }))
            }
        }}}
        // config={defaultPlotlyArguments.config}
        style={defaultPlotlyArguments.style}
    />
}