import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Col, Layout, Row, Space, Typography } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import { StandardPlotOdors } from './components/standardPlotOdors';
import { ProjectedPlot } from './components/projectedPlot';
import { StandardPlotChemistry } from './components/standardPlotChemistry';

function App() {
  return (
    <Layout>
      <Header></Header>
      <Content>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24} style={{textAlign: 'center'}}>
                <Typography.Title level={2}>Olfactive mapping tool</Typography.Title>
              </Col>
            </Row>
            <Row justify='center'>
              <Col span={12}>
                  <StandardPlotChemistry/>
                  {/* <Space style={{height: '100vh'}}>
                  </Space> */}
              </Col>
              <Col span={12}>
                  <StandardPlotOdors/>
                {/* <Space style={{height: '100vh'}}>
                </Space> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <Footer></Footer>
    </Layout>
  );
}

export default App;
